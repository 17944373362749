const API = {
  BASE_URL: "https://whatsapp1-server.bairuhatech.com/",
  // BASE_URL: "http://localhost:8017/",

  LOGIN: "userauth/login", //POST
  SIGN_UP: "userauth/signup",
  FORGOTT_PASS: "", //POST
  CHANGE_PASSWORD: "", //POST
  EDIT_PROFILE: "", //POST

  PROJECT_LIST: "project/list", //POST
  PROJECT_ADD: "project/create", //POST
  PROJECT_EDIT: "project/edit/", //POST
  PROJECT_DELETE: "project/delete/", //GET
  PROJECT_DETAILS: "project/details/", //GET
  PROJECT_QR: "message/new_session", //POST
  PROJECT_REVERIFY: "",

  CONTACTS_LIST: "contacts/list", //GET
  CONTACTS_GETONE: "contacts/details/", //GET
  CONTACTS_ADD: "contacts/create", //POST
  CONTACTS_EDIT: "contacts/update/", //PUT
  CONTACTS_DELETE: "contacts/delete/", //DELETE
  CONTACT_EXCEL: "",

  TEMPLATES_LIST: "templates/list", //GET
  TEMPLATES_GETONE: "templates/details/", //GET
  TEMPLATES_ADD: "templates/create", //POST
  TEMPLATES_EDIT: "templates/update/", //PUT
  TEMPLATES_DELETE: "templates/delete/", //DELETE

  MSG_SEND: "message/send_message", //POST
  MSG_BULK: "", //POST
  MSG_LIST: "", //POST
  MSG_DETAILS: "", //GET

  COMPANY_LIST: "", //POST
  COMPANY_ADD: "company/create-company", //POST
  COMPANY_EDIT: "", //POST
  COMPANY_DELETE: "", //GET

  TOKEN_GENERATE: "",
  TOKEN_DELETE: "",

  GET_COMPANY: "company",
  FILTER_COMPANY: "company/filter",
  FILTER_PROJECT: "project/filter",
  GET_PROJECT: "project",

  //V1
  BROADCAST_V1: "v1/broadCast",
  SINGLE_MESSAGE_V1: "v1/message",
};
export default API;
